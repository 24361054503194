import React from "react";


import Layout from "./layout";
import HouseOne from "../assets/picture/hp/house.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleRight, faEnvelope, faLink, faMobileScreen} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type resultProps = {
    globals: {}
}

const ApartmentPage = ({ globals }: resultProps ) => {
    const { container } = ApartmentPage.styles;

    return(
        <Layout>
            <div className="container mx-auto px-4 lg:mt-16 mb-16">
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="sm:px-4">
                        <div className="w-full max-w-3xl mt-5 p-8 bg-white rounded-lg shadow-xl">
                            <h1 className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	border-peer pl-2">
                                Appartement renoveren?
                            </h1>
                            <div className="p-2">
                                <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-1000 font-bold">
                                    Check je huis is helaas niet geschikt voor appartementen.
                                    Je kan je wel gratis laten begeleiden bij De Energiecentrale.
                                </p>
                                <p className="mt-4 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                                    Het opknappen van je appartement is vaak complex.
                                    Sommige renovaties, zoals dakisolatie, moeten collectief
                                    aangepakt worden, andere, zoals het plaatsen van isolerend glas,
                                    kunnen ook individueel. Toch heb je er voordeel bij om dit ook
                                    gezamelijk aan te pakken. De Energiecentrale helpt je (verhuur)appartement
                                    vlot in orde te brengen. Van het overtuigen van de mede eigenaars,
                                    over aannemers zoeken of offertes vergelijken tot opvolging van de werken.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:mt-16">
                        <div className="flex w-full max-w-3xl mt-5 bg-white rounded-lg shadow-xl">
                            <div className="w-full sm:w-6/12">
                                <img src={HouseOne} alt="House's logo" />
                            </div>
                            <div className="text-center w-full sm:w-6/12 sm:text-left sm:flex align-middle	place-items-center	items-center">
                                <div>
                                    <h2
                                        className="text-xl md:text-xl xl:text-xl 2xl:text-2xl font-bold w-full text-gray-900"
                                    >
                                        Home 2 Home
                                    </h2>

                                    <button
                                        type="submit"
                                        className="mt-4 w-full lg:w-auto text-white bg-peer hover:bg-peer-dark focus:ring-4 focus:outline-none focus:ring-peer-accent font-medium text-sm px-5 py-2.5 text-center content-end h-12 self-center "
                                    >
                                        Boek een meeting
                                    </button>

                                    <a href="https://h2hreno.be/" target="_blank">
                                        <div className="flex mt-4">
                                            <h2
                                                className="text-peer font-bold no-underline hover:underline cursor-pointer">
                                                Of bezoek deze site
                                            </h2>
                                            <FontAwesomeIcon
                                                icon={faCircleRight as IconProp}
                                                className="w-5 h-5 ml-1 mt-1 -m text-peer cursor-pointer	"
                                            />
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    )
};

ApartmentPage.styles = {
    container: {
        marginTop: '12%',
    },
};

export default ApartmentPage;
