import React from "react";
import { Link } from "react-router-dom";

import Layout from "./layout";
import cones from "../assets/cones.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type resultProps = {
  globals: {};
};

const NotFound = ({ globals }: resultProps) => {
  const { conesImage } = NotFound.styles;

  return (
    <Layout>
      <div className="container mx-auto lg:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="lg:mt-10">
            <div className="w-full flex flex-row mt-5">
              <div className="border-4 border-[#00AFAA]" />
              <p className="font-sans font-bold text-black text-[56px] ml-10">
                Pagina
                <br />
                niet
                <br />
                gevonden
              </p>
            </div>

            <div className="mt-[25px] text-left">
              <div className="ml-12">
                <div className="ml-120">
                  <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    Wellicht kom je hier terecht door een verouderde link of
                    <br />
                    is de pagina die zoekt verplaatst, verwijderd of tijdelijk
                    <br />
                    niet beschikbaar.
                  </p>

                  <Link to="/">
                    <div className="flex mt-4">
                      <FontAwesomeIcon
                        icon={faCircleLeft as IconProp}
                        className="w-5 h-5 ml-1 mt-1 -m text-peer cursor-pointer	"
                      />
                      <p className="ml-2 pt-[3px] text-peer font-bold no-underline hover:underline cursor-pointer">
                        Terug naar de startpagina
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:mb-16 lg:mt-16">
            <div className="lg:mt-16" style={conesImage} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

NotFound.styles = {
  conesImage: {
    backgroundImage: `url(${cones})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "contain",
    marginBottom: 75,
    minHeight: 600,
    height: "100%",
    width: "97%",
  },
};

export default NotFound;
